<script setup>
import { onMounted, computed, ref } from 'vue';
import HeaderLayout from '@/Layouts/HeaderLayout.vue';
import { Head, Link, usePage, useForm } from '@inertiajs/vue3';
import  PageLayout  from '@/Layouts/PageLayout.vue';
import CookieSVG from '../../../images/cookies.svg?component';
import { loadLanguageAsync, getActiveLanguage, trans } from 'laravel-vue-i18n';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import SecondaryButton from '@/Components/SecondaryButton.vue';

const currentLanguage = ref(getActiveLanguage());

const page = usePage();

const show = ref(!page.props.consentRequested);

const form = useForm({
  given: 0
});

const accept = (given) => {
  let formData = new FormData();
  formData.append('given', given);
  form.post(route('consent.store'), formData);
  show.value = false;
};

const modalText = ref(trans('policy.modal_text', {
  privacy_policy: `<a href="${page.props.ziggy.routes[`privacy.${currentLanguage.value}`]}" target="_blank" class="transition-all whitespace-nowrap text-main-color hover:opacity-80 text-primary">${trans('policy.privacy_policy')}</a>`,
  cookie_policy: `<a href="${page.props.ziggy.routes[`cookie.${currentLanguage.value}`]}" target="_blank" class="transition-all whitespace-nowrap text-main-color hover:opacity-80 text-primary">${trans('policy.cookie_policy')}</a>`
}));

</script>

<template>
  <div v-if="show">
    <div class="bg-white shadow-header fixed bottom-[93px] md:bottom-0 rounded-[15px_15px_0_0] w-full z-50">
      <CookieSVG class='h-[40px] w-[40px] mx-auto my-6' />
      <div class="container mx-auto pb-12">
        <p class='m-0 text-gray-500 text-center' v-html="modalText"></p>
        <div class="w-full grow-1 flex flex-row items-center justify-center gap-[8px] mt-4">
          <PrimaryButton @click="accept(1)">{{ $t('policy.accept_action') }}</PrimaryButton>
          <SecondaryButton @click="accept(0)">{{ $t('policy.decline_action') }}</SecondaryButton>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>